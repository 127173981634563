import { Button } from "antd";
import { useUserLoanApproval } from "../../../hooks/zustand_hooks/useUserLoanApproval";
import { RxCheckCircled } from "react-icons/rx";
import { PiWarningCircle } from "react-icons/pi";
import { useMandateAcc } from "../../../api/mutation";
import useCustomMessage from "../../../hooks/zustand_hooks/useCustomMessage";
import { decryptData } from "../../../utils/cryptData";

export default function BankAccounts() {
  const {
    updateCurrentPage,
    data: { bank_accounts, company_detail },
  } = useUserLoanApproval();

  const { showMessage, contextHolder } = useCustomMessage();

  const getAccMutation = useMandateAcc();

  const continueMandate = () => {
    let nextAccount = bank_accounts?.[0];
    getAccMutation.mutate(nextAccount?.id, {
      onError: (error) => {
        const errorMessage = error?.response?.data?.message ?? error?.message;

        showMessage("error", errorMessage);
      },
      onSuccess: (data) => {
        const accData = data?.data?.data;

        bank_accounts[0] = accData;

        updateCurrentPage("SET_MANDATE", { bank_accounts });
      },
    });
  };

  return (
    <>
      <div className="max-w-lg md:max-w-md mx-3 sm:mx-auto md:mx-auto">
        <div className="mx-auto px-1 py-4 rounded-lg bg-white shadow-sm ">
          <h2 className="font-[600] text-[22px] mb-3 text-center">
            {company_detail?.name}
          </h2>

          <h5 className="font-[400] text-[18px] text-center mb-5">
            Complete Mandate process
          </h5>

          <div className="border rounded-lg border-[#D9D9D996] px-5 py-3">
            <h4 className="font-[600] text-[18px] mb-2">
              Your Bank Account(s)
            </h4>

            {bank_accounts?.map((item, index) => {
              return (
                <div className="flex justify-between my-7 col-span-2 pe- items-center border-b w-full border-b-[#CFCDCC]">
                  <div className="flex gap-x-2 items-center">
                    <p className="text-[14px] flex">
                      {decryptData(item?.initiate_mandate_data?.bank)}
                      {decryptData(item?.status) === "active" ? (
                        <RxCheckCircled color="green" size={"3vh"} />
                      ) : (
                        <PiWarningCircle color="#733534" size={"3vh"} />
                      )}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p className="font-[600] text-[14px]">
                      {decryptData(item?.initiate_mandate_data?.account_number)}
                      {decryptData(item?.is_required) && item !== "active" ? (
                        <sup
                          className={`text-center font-[400] mb-1 text-yellow-500 text-xs`}
                        >
                          (Required)
                        </sup>
                      ) : (
                        ""
                      )}
                    </p>{" "}
                  </div>
                </div>
              );
            })}

            <div>
              <p className="text-[#033F4B] text-[400] text-[14px]">
                By continuing this page you have authorised{" "}
                {company_detail?.name} to set mandate on all the accounts above
              </p>
            </div>
            <div className="mt-5 flex justify-center items-center">
              <Button
                size="large"
                shape={"round"}
                loading={getAccMutation?.isPending}
                className="rounded-[8px] bg-[#2A2A29] text-[#FFF] text-[18px]  hover:!bg-[#2A2A29] hover:!text-[#FFF]"
                onClick={continueMandate}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>

      {contextHolder}
    </>
  );
}
