import { forwardRef } from "react";
import { Input as AntInput, ConfigProvider } from "antd";

export const PasswordInput = forwardRef(({ className, ...rest }, ref) => {
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              hoverBorderColor: "#733534",
              activeBorderColor: "#733534",
              activeShadow: "0",
            },
          },
        }}
      >
        <AntInput.Password
        ref={ref}
          {...rest}
          className={`h-[42px] text-[14px] ${className}`}
        />
      </ConfigProvider>
    </>
  );
});

