import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { InputNumber } from "../../../common/form_inputs/Input";
import CustomButton from "../../../common/CustomButton";
import { useCustomerLoanApplication } from "../../../../hooks/zustand_hooks/useCustomerLoanApplication";
import { useUpdateLoan } from "../../../../api/mutation";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";

const LoanRequest = () => {
  const {
    data: { loan_setting: settings, form_values, edit_loan_info, userDetail },
    updateCurrentPage,
  } = useCustomerLoanApplication();

  const loan_setting = settings || userDetail?.loanSetting;

  const { showMessage, contextHolder } = useCustomMessage();

  const { mutate, isPending } = useUpdateLoan(userDetail?.id);

  const {
    handleSubmit,
    formState: { errors },
    trigger,
    getValues,
    watch,
    control,
  } = useForm({
    defaultValues: {
      loan_amount: form_values?.loan_amount,
      max_repayment_period: form_values?.max_repayment_period,
    },
    mode: "onBlur", // Validate on blur or change
    criteriaMode: "all",
  });

  const onSubmit = (data) => {
    if (edit_loan_info) {
      const payload = {
        key: "amount",
        amount: data?.loan_amount,
        repayment_period: data?.max_repayment_period,
      };

      mutate(payload, {
        onError: (error) => {
          const errMsg = error?.response?.data?.message || error?.message;
          showMessage("error", errMsg);
        },
        onSuccess: (data) => {
          updateCurrentPage("LOAN_APPLICATION_SUMMARY", {
            form_values: { ...form_values, ...data },
            edit_loan_info: false,
          });
        },
      });
    } else {
      updateCurrentPage("LET_KNOW_YOU", { form_values: data });
    }
  };

  const handleKeyPress = (event) => {
    const inputValue = event.target.value;

    // Allow digits and a single decimal point
    if (
      !/[\d.]/.test(event.key) || // Block non-numeric and non-period keys
      (event.key === "." && inputValue.includes(".")) // Block multiple periods
    ) {
      event.preventDefault();
    }
  };

  const loan_amount = parseFloat(watch("loan_amount"));
  const max_repayment_period = parseFloat(watch("max_repayment_period"));

  const interest =
    parseFloat(loan_setting?.interest || userDetail?.loanSetting?.interest) ??
    0;

  const loanSummaryAmount = useMemo(
    () => loan_amount * (interest / 100) * max_repayment_period + loan_amount,
    [loan_amount, interest, max_repayment_period]
  );

  return (
    <>
      <div className="mt-4">
        <div className="w-full max-w-lg px-6 py-4 rounded-lg bg-white shadow-sm">
          <h4 className="font-[600] text-[18px] mb-2">Loan Request</h4>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label htmlFor="loan_amount" className="font-[400] text-[14px]">
                Enter Loan Amount{" "}
                <small style={{ fontSize: "10px" }} className="text-yellow-500">
                  Maximum Loan {loan_setting?.maximum_amount}
                </small>
              </label>
              <Controller
                name="loan_amount"
                control={control}
                rules={{
                  required: "This field is required",
                  validate: (value) => {
                    // const numValue = Number(value?.replace(/\D/g, ""));
                    if (value > loan_setting?.maximum_amount) {
                      return `Amount cannot exceed NGN ${loan_setting?.maximum_amount.toLocaleString()}`;
                    }
                    if (value < 10) {
                      return "Amount cannot be less than NGN 10";
                    }
                    return true;
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputNumber
                    {...field}
                    prefix="NGN"
                    defaultValue={getValues("loan_amount")}
                    className={`text-[14px] ps-2 w-full flex items-center text-[#033F4B] ${
                      errors.loan_amount ? "error" : ""
                    }`}
                    status={errors.loan_amount ? "error" : ""}
                    onChange={(value) => {
                      field.onChange(value);
                      trigger("loan_amount");
                    }}
                    onKeyPress={handleKeyPress}
                  />
                )}
              />
              <small style={{ fontSize: "13px" }} className="text-red-500">
                {errors.loan_amount && errors.loan_amount.message}
              </small>
            </div>
            <div className="mb-2">
              <label
                htmlFor="max_repayment_period"
                className="font-[400] text-[14px]"
              >
                Set Repayment Period{" "}
                <small style={{ fontSize: "10px" }} className="text-yellow-500">
                  Maximum period {loan_setting?.repayment_period}
                </small>
              </label>
              <div className="flex items-start gap-x-3">
                <div className="w-full">
                  <Controller
                    name="max_repayment_period"
                    control={control}
                    rules={{
                      required: "This field is required",
                      validate: (value) => {
                        // const numValue = Number(value?.replace(/\D/g, ""));
                        if (value > loan_setting?.repayment_period) {
                          return `Repayment period cannot exceed  ${loan_setting?.repayment_period.toLocaleString()}`;
                        }
                        return true;
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputNumber
                        {...field}
                        defaultValue={getValues("max_repayment_period")}
                        className={`text-[14px] ps-2 w-full flex items-center text-[#033F4B] ${
                          errors.max_repayment_period ? "error" : ""
                        }`}
                        status={errors.max_repayment_period ? "error" : ""}
                        onChange={(value) => {
                          field.onChange(value);
                          trigger("max_repayment_period");
                        }}
                        onKeyPress={handleKeyPress}
                      />
                    )}
                  />

                  <small style={{ fontSize: "13px" }} className="text-red-500">
                    {errors.max_repayment_period &&
                      errors.max_repayment_period.message}
                  </small>
                </div>
                <p
                  className="text-white bg-[#131842] hover:bg-[#131842] hover:text-white rounded-md text-[13px] px-3 py-1"
                  size="small"
                >
                  {loan_setting?.repayment_type}
                </p>
              </div>
            </div>
            {loanSummaryAmount ? (
              <div className="my-5 flex justify-center">
                <p className="bg-[#D9D9D9] rounded py-3 px-8">
                  Total Repayment Amount: {loanSummaryAmount}
                </p>
              </div>
            ) : null}

            <div className="mt-6 flex justify-center">
              <CustomButton
                className={"w-full"}
                type="submit"
                loading={isPending}
              >
                Continue
              </CustomButton>
            </div>
          </form>
        </div>
      </div>

      {contextHolder}
    </>
  );
};

export default LoanRequest;
