import React from "react";

const SmsShareButton = ({ url, title, children }) => {
  const handleClick = () => {
    const message = `${title}\n${url}`;
    const encodedMessage = encodeURIComponent(message);
    const smsUrl = `sms:?body=${encodedMessage}`;

    window.location.href = smsUrl;
  };

  return (
    <button
      onClick={handleClick}
      style={{ cursor: "pointer", background: "none", border: "none" }}
    >
      {children}
    </button>
  );
};

export default SmsShareButton;
