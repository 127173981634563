import { Button } from "antd";
import { useMemo, useState, useEffect, useRef } from "react";
import { numToWords } from "num-to-words";
import { useUserLoanApproval } from "../../../hooks/zustand_hooks/useUserLoanApproval";
import { useTimeCounter } from "../../../hooks/useTimeCounter";
import { useCheckPayment } from "../../../api/admin_mutation";
import useCustomMessage from "../../../hooks/zustand_hooks/useCustomMessage";
import { IoMdArrowRoundBack } from "react-icons/io";
import ButtonConfig from "../../../components/common/ButtonConfig";
import CustomButton from "../../../components/common/CustomButton";
import OutlineCustomButton from "../../../components/common/OutlineCustomButton";
import { useMandateAcc } from "../../../api/mutation";
import { decryptData } from "../../../utils/cryptData";

const numberToOrdinalWords = (num) => {
  const ones = [
    "zeroth",
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
    "eleventh",
    "twelfth",
    "thirteenth",
    "fourteenth",
    "fifteenth",
    "sixteenth",
    "seventeenth",
    "eighteenth",
    "nineteenth",
  ];

  const tens = [
    "",
    "",
    "twentieth",
    "thirtieth",
    "fortieth",
    "fiftieth",
    "sixtieth",
    "seventieth",
    "eightieth",
    "ninetieth",
  ];

  if (num < 20) {
    return ones[num];
  } else if (num < 100) {
    const ten = Math.floor(num / 10);
    const one = num % 10;
    return tens[ten] + (one ? "-" + ones[one] : "");
  }

  // Extend this logic for larger numbers as needed
  return num.toString();
};

export default function SetMandate({ data, company }) {
  const {
    updateCurrentPage,
    data: { bank_accounts, company_detail },
  } = useUserLoanApproval();

  // let

  const { showMessage, contextHolder } = useCustomMessage();

  const getAccMutation = useMandateAcc();

  const [isLoading, setIsLoading] = useState(false);

  const [selectedBankIndex, setSelectedBankIndex] = useState(1);

  const [btnState, setBtnState] = useState("");

  const [accountStep, setAccountStep] = useState({
    totalStep: bank_accounts?.length,
    currentStep: 0,
  });

  const currentAccount = bank_accounts?.[accountStep?.currentStep];

  const format_account_state = useMemo(
    () => ({
      active: true,
      inactive: false,
    }),
    []
  );

  const [mandateSet, setMandateSet] = useState({
    step: 0,
    status: format_account_state[currentAccount?.status] || false,
    remainSteps: bank_accounts?.length,
  });

  const createdAt = useRef(new Date().toISOString());

  const createdAtDate = new Date(
    currentAccount?.createdAt ?? createdAt.current
  );

  // Add 25 minutes (25 * 60 * 1000 milliseconds) to the createdAt time
  const expiredAtDate = new Date(createdAtDate.getTime() + 60 * 60000);

  // Convert the expiredAt Date object back to an ISO string

  const expiredAt = expiredAtDate.toISOString();

  const { timeRemaining, formatTime } = useTimeCounter(expiredAt);

  const mutation = useCheckPayment(currentAccount?.id);

  const mandateCred =
    currentAccount?.initiate_mandate_data?.transfer_destinations;

  const remainStep = bank_accounts?.length - (accountStep?.currentStep + 1);

  const recipientAccount = useMemo(() => {
    return mandateCred?.[selectedBankIndex];
  }, [selectedBankIndex, mandateCred]);

  const handleChangeRecipientAcc = () => {
    selectedBankIndex ? setSelectedBankIndex(0) : setSelectedBankIndex(1);
  };

  useEffect(() => {
    setMandateSet((prev) => ({
      ...prev,
      status: format_account_state[currentAccount?.status],
    }));
  }, [accountStep, currentAccount?.status, format_account_state]);

  //======================================== FUNCTION FOR I HAVE SENT MONEY ===================================
  const moneySent = () => {
    //======================using this to test the flow without mutation========================
    // setMandateSet({
    //   step: accountStep?.currentStep,
    //   status: true,
    //   remainSteps: remainStep
    // });
    //============================= ends here ====================================

    //==================== this for real one with mutation======================
    setIsLoading(true);
    mutation.mutate("", {
      onError: (err) => {
        const errMsg = err?.response?.data?.message ?? err?.message;
        showMessage("error", errMsg);
      },
      onSuccess: (data) => {
        console.log(data);
        setMandateSet({
          step: accountStep?.currentStep,
          status: true,
          remainSteps: remainStep,
        });
      },
      onSettled: () => {
        setIsLoading(false);
      },
    });
    //========================================================
  };
  //================== FUNCTION ENDS HERE ========================================================

  const prevStep = () => {
    //will make request here to fetch mandate for nextAccount

    let nextAccount = bank_accounts?.[accountStep?.currentStep - 1];

    //make request with nexAccount?.id to get mandate for nextAccount, inside the request is where I put the other codes such that when it is successfull, it will insert the response to the bank_accounts

    getAccMutation.mutate(nextAccount?.id, {
      onError: (error) => {
        const errorMessage = error?.response?.data?.message ?? error?.message;

        showMessage("error", errorMessage);
      },
      onSuccess: (data) => {
        const accData = data?.data?.data;

        bank_accounts[
          accountStep?.currentStep === 0 ? 0 : accountStep?.currentStep - 1
        ] = accData;

        if (accountStep?.currentStep === 0) {
          setAccountStep({
            ...accountStep,
            currentStep: 0,
          });
          setMandateSet({ step: 0 });
        } else {
          setAccountStep({
            ...accountStep,
            currentStep: accountStep?.currentStep - 1,
          });
          setMandateSet({ step: mandateSet?.step - 1 });
        }
      },
    });
  };

  const nextStep = (state) => {
    setBtnState(state); //using this to change the button state

    let nextAccount = bank_accounts?.[accountStep?.currentStep + 1];
    //will make request here to fetch mandate for nextAccount
    // make request with nexAccount?.id to get mandate for nextAccount, inside the request is where I put the other codes such that when it is successfull, it will insert the response to the bank_accounts
    if (nextAccount) {
      getAccMutation.mutate(nextAccount?.id, {
        onError: (error) => {
          const errorMessage = error?.response?.data?.message ?? error?.message;

          showMessage("error", errorMessage);
        },
        onSuccess: (data) => {
          const accData = data?.data?.data;

          bank_accounts[accountStep?.currentStep + 1] = accData;

          if (mandateSet?.step === accountStep?.totalStep - 1) {
            updateCurrentPage("BANK_ACCOUNTS");
          } else {
            setAccountStep({
              ...accountStep,
              currentStep: accountStep?.currentStep + 1,
            });
            setMandateSet({
              step: mandateSet?.step + 1,
              status: false,
              remainSteps: remainStep,
              // remainSteps: bank_accounts?.length - (accountStep?.currentStep + 1),
            });
          }
        },
      });
    } else {
      setMandateSet({
        step: accountStep?.currentStep,
        status: true,
        remainSteps: remainStep,
      });
    }
  };

  // useEffect(() => {
  //   if (mutation.isPending) {
  //     setIsLoading(true);
  //   } else if (mutation.isError) {
  //     setIsLoading(false);
  //     const error =
  //       mutation?.error?.response?.data?.message ?? mutation?.error?.message;

  //     showMessage("error", error);
  //   } else if (mutation.isSuccess) {
  //     setIsLoading(false);
  //     const res = mutation?.data?.data?.data;
  //   }
  // }, [mutation?.data?.data?.data, mutation?.error?.message, mutation?.error?.response?.data?.message, mutation.isError, mutation.isPending, mutation.isSuccess, mutation.status, showMessage]);

  const numberOfSuccessfullMandate = useMemo(() => {
    return bank_accounts?.filter((acc) => acc.status === "active").length;
  }, [bank_accounts]);

  const skippedMandate = () => {
    setAccountStep({
      ...accountStep,
      currentStep: 0,
    });
    setMandateSet({ step: 0, remainSteps: remainStep });
  };

  return (
    <>
      <div className="max-w-lg md:max-w-lg mx-3 sm:mx-auto md:mx-auto px-1 py-4 rounded-lg bg-white shadow-sm">
        <h2 className="font-[600] text-[22px] text-center">
          {company_detail?.name}
        </h2>

        <h5 className="font-[400] text-[16px] text-center mb-2">
          Complete Mandate process
        </h5>
        {accountStep?.currentStep !== 0 && (
          <ButtonConfig>
            <Button
              size="small"
              onClick={prevStep}
              icon={<IoMdArrowRoundBack />}
              className="px-5 mb-2"
            ></Button>
          </ButtonConfig>
        )}
        <div className="border rounded-lg border-[#D9D9D996] px-5 py-3">
          <div className="col-lg- mx-auto max-w-lg w-full">
            <div>
              <div className="flex">
                <span>
                  {
                    // mandateSet?.status
                    //   ? numberOfSuccessfullMandate
                    //   :
                    accountStep?.currentStep + 1
                  }
                  /{accountStep?.totalStep}
                </span>
                <div className="w-full">
                  <h4 className="text-center font-[600]">
                    Set {numberToOrdinalWords(accountStep?.currentStep + 1)}{" "}
                    Mandate
                  </h4>
                </div>
              </div>

              <div className="mt-2">
                {mandateSet?.status ? null : (
                  <div>
                    <p className="text-sm">
                      By making the transfer below, you authorize{" "}
                      {company?.name} to set a direct debit mandate on your bank
                      account to ensure repayment.{" "}
                    </p>
                    <p className="font-bold text-center my-2">
                      Transfer 50 naira
                    </p>
                    {currentAccount?.isRequired ? (
                      <p className={`text-center mb-1 text-yellow-500 text-md`}>
                        This is account is required to set mandate on
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                <div>
                  <div
                    className="border rounded mx-auto ps-5 pe-3 py-4"
                    style={{ backgroundColor: "#FAF9F9" }}
                  >
                    <div>
                      {mandateSet?.status ? null : (
                        <div className="h-100 flex flex-col gap-5 ">
                          <div>
                            <p
                              className="flex mb-3"
                              style={{
                                fontWeight: 500,
                                fontSize: "15px",
                                lineHeight: "5px",
                              }}
                            >
                              {"SEND FROM"}
                            </p>
                            <p
                              className="flex justify-between my-2 items-center"
                              style={{
                                fontWeight: 600,
                                fontSize: "18px",
                                color: "#000",
                                lineHeight: "12px",
                              }}
                            >
                              <span className="flex flex-col">
                                {decryptData(
                                  currentAccount?.initiate_mandate_data
                                    ?.account_number
                                ) ?? ""}
                                <small
                                  style={{
                                    fontWeight: 400,
                                    fontSize: "13px",
                                    lineHeight: "5px",
                                    marginTop: "13px",
                                  }}
                                >
                                  {decryptData(
                                    currentAccount?.initiate_mandate_data?.bank
                                  ) ?? ""}
                                </small>
                              </span>
                              {/* <img
                            src={mandateCred?.[0]?.icon}
                            alt={"Fidelity"}
                            width={50}
                          /> */}
                            </p>
                          </div>

                          <div>
                            <p
                              className="flex justify-between items-center mb-3"
                              style={{
                                fontWeight: 500,
                                fontSize: "15px",
                                lineHeight: "5px",
                              }}
                            >
                              <span>SEND TO</span>
                              <span
                                className={`text-blue-500 font-smibold cursor-pointer ${
                                  bank_accounts?.length ? "block" : "hidden"
                                }`}
                                onClick={handleChangeRecipientAcc}
                              >
                                CHANGE
                              </span>
                            </p>
                            <p
                              className="flex justify-between my-2"
                              style={{
                                fontWeight: 600,
                                fontSize: "18px",
                                color: "#000",
                                lineHeight: "12px",
                              }}
                            >
                              <span className="flex flex-col">
                                {decryptData(
                                  recipientAccount?.account_number
                                ) ?? ""}
                                <small
                                  style={{
                                    fontWeight: 400,
                                    fontSize: "13px",
                                    lineHeight: "5px",
                                    marginTop: "12px",
                                  }}
                                >
                                  {decryptData(recipientAccount?.bank_name) ??
                                    ""}
                                </small>
                              </span>
                              <img
                                src={decryptData(recipientAccount?.icon)}
                                alt={decryptData(recipientAccount?.bank_name)}
                                width={50}
                              />
                            </p>
                          </div>
                        </div>
                      )}
                      <div className="mt-3 text-center">
                        {mandateSet?.status ? (
                          mandateSet?.remainSteps === 0 ? (
                            <p className="flex flex-col gap-4">
                              <span className="font-[500]">
                                Mandate Set Successfully
                              </span>

                              <span className="">
                                {numberOfSuccessfullMandate} mandate
                                successfully set.
                              </span>
                              <span className="">Thank you</span>

                              <CustomButton onClick={skippedMandate}>
                                Authorize skipped mandate
                              </CustomButton>
                            </p>
                          ) : (
                            <div>
                              <h2 className="font-semibold">
                                Mandate set successfully
                              </h2>
                              <p>
                                <span className="capitalize">
                                  {numToWords(
                                    accountStep?.totalStep -
                                      (accountStep?.currentStep + 1)
                                  )}
                                </span>{" "}
                                more mandates to go
                              </p>
                            </div>
                          )
                        ) : (
                          <>
                            <div className="mt-3">
                              {timeRemaining <= 0 ? (
                                <p>
                                  {" "}
                                  <small
                                    style={{
                                      color: "#940B0B",
                                      fontSize: "15px",
                                    }}
                                  >
                                    This account details has expired
                                  </small>
                                </p>
                              ) : (
                                <div className="tw-flex tw-items-center">
                                  <p>
                                    This{" "}
                                    {decryptData(recipientAccount?.bank_name)}{" "}
                                    account expires in{" "}
                                    <span className="w-auto py-1 tw-px-3 text-sm tw-text-[#333332]">
                                      {timeRemaining > 0
                                        ? formatTime(timeRemaining)
                                        : "00:00"}
                                    </span>
                                  </p>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className={`max-w-lg mx-auto ${
                      mandateSet?.remainSteps === 0 ? "hidden" : "flex"
                    } flex-col gap-3 mt-3`}
                  >
                    <CustomButton
                      loading={
                        isLoading ||
                        (getAccMutation?.isPending && btnState !== "skip")
                      }
                      onClick={
                        format_account_state[currentAccount?.status] ||
                        mandateSet?.status
                          ? nextStep
                          : moneySent
                      }
                    >
                      {format_account_state[currentAccount?.status] ||
                      mandateSet?.status
                        ? "Next"
                        : "I’ve sent the money"}
                    </CustomButton>

                    {!mandateSet?.status && (
                      <OutlineCustomButton
                        onClick={() => nextStep("skip")}
                        loading={
                          btnState === "skip" && getAccMutation?.isPending
                        }
                      >
                        Skip
                      </OutlineCustomButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {contextHolder}
    </>
  );
}
