import React, { useEffect, useMemo } from "react";
import { formatNumberOnly } from "../../../../utils/formatNumber";
import { Button } from "antd";
import { useProfileData } from "../../../../hooks/useProfileData";
import moment from "moment";
import { useGetStatistics } from "../../../../api/admin_mutation";
import { decryptData } from "../../../../utils/cryptData";

const HeaderContent = () => {
  const { data, isAdmin } = useProfileData();

  const { mutate, data: statData, isPending } = useGetStatistics(isAdmin);

  useEffect(() => {
    mutate({
      start_date: moment().startOf("today").format("YYYY/MM/DD"),
      end_date: moment().endOf("today").format("YYYY/MM/DD"),
    });
  }, [isAdmin, mutate]);

  const statisticData = useMemo(() => {
    return statData?.data?.data;
  }, [statData]);


  return (
    <>
      <div className="flex gap-x-3 gap-y-1 flex-wrap">
        {
          <p className="font-[500] capitalize text-[15px]">
            Subscription{" "}
            <small
              className={`font-[300] text-[12px] ${
                data?.company?.subscriptions?.[0]?.status
                  ? "text-[#188D04]"
                  : "text-red-500"
              }`}
            >
              {data?.company?.subscriptions?.[0]?.status
                ? "Active"
                : "Inactive"}
            </small>
          </p>
        }
        <p className="font-[500] capitalize text-[15px]">
          Wallet{" "}
          <small className="font-[300] text-[12px] text-[#188D04]">
            NGN{" "}
            {formatNumberOnly(decryptData(data?.company?.wallet_balance) ?? 0)}
          </small>
        </p>
        {
          // data?.company?.subscriptions?.length ? (
          <p
            className="text-white bg-[#131842] hover:bg-[#131842] hover:text-white rounded-md text-[13px] px-3 py-1"
            size="small"
          >
            Monthly Mandate Limit:{" "}
            <small className="font-[300] text-[12px] text-[#fff]">
              {String(statisticData?.current_sub_mandates ?? 0)
              }
              {data?.company?.subscriptions?.length
                ? `/${data?.company?.subscriptions?.[0]?.plan?.number_of_mandate}`
                : ""}
            </small>
          </p>
          // ):""
        }
      </div>
    </>
  );
};

export default HeaderContent;
