import { useMemo } from "react";
import CustomButton from "../../../common/CustomButton";
import MonoConnect from "@mono.co/connect.js";
import { useCustomerLoanApplication } from "../../../../hooks/zustand_hooks/useCustomerLoanApplication";
import { useLoanKyc } from "../../../../api/mutation";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";

const ShareBankStatement = ({
  paramID,
  updateStatement,
  makeRequest,
  updateLoading,
}) => {
  const {
    data: { userDetail },
    updateCurrentPage,
  } = useCustomerLoanApplication();

  const { mutate, isPending } = useLoanKyc(paramID);

  const { showMessage, contextHolder } = useCustomMessage();

  const monoConnect = useMemo(() => {
    const customer = {
      name: `${userDetail?.first_name} ${userDetail?.last_name}`,
      email: userDetail?.email,
      identity: {
        type: "nin",
        number: userDetail?.nin,
      },
    };

    const monoInstance = new MonoConnect({
      key: "live_pk_v9ldal9jrgagyxvrtl1a",
      scope: "auth",
      data: { customer },
      onClose: () => console.log("Widget closed"),
      onLoad: () => console.log("Widget loaded successfully"),
      onSuccess: ({ code }) => {
        const payload = {
          code: code,
        };

        if (updateStatement) {
          makeRequest(payload);
        } else {
          mutate(payload, {
            onError: (error) => {
              const errMsg = error?.response?.data?.message || error?.message;

              showMessage("error", errMsg);
            },
            onSuccess: (res) => {
              if (userDetail?.is_returning_customer) {
                updateCurrentPage("DISBURSEMENT_ACCOUNT");
              } else {
                updateCurrentPage("APPLICATION_COMPLETED", {
                  response: {
                    title: "KYC Process Completed",
                    desc: "Your application will be reviewed",
                    continue: true,
                  },
                });
              }
            },
          });
        }
      },
    });
    monoInstance?.setup();
    return monoInstance;
  }, []);

  return (
    <>
      <main className="flex flex-col gap-y-10 w-full max-w-lg mx-auto px-4">
        <h3 className="font-[500] text-[16px] text-center text-[#323C4D]">
          Share Bank Statement
        </h3>
        <p className="text-[22px] font-[300] leading-[33px]">
          Kindly continue with Mono to share your bank statement with{" "}
          {userDetail?.company?.name}.
        </p>
        <div className="mb-5 flex justify-center">
          <CustomButton
            className={"w-full"}
            onClick={() => monoConnect.open()}
            loading={isPending || updateLoading}
          >
            Share bank statement with Mono
          </CustomButton>
        </div>
      </main>

      {contextHolder}
    </>
  );
};

export default ShareBankStatement;
