import { create } from "zustand";

//expected value for current pages BVN_OTP, LOAN_SUMMARY

export const useUserLoanApproval = create((set) => {
  return {
    isOpen: false,
    currentPage: "LOAN_SUMMARY",
    data: {},
    updateData: (data) =>
      set((state) => ({ data: { ...state.data, ...data } })),
    updateCurrentPage: (page, data) =>
      set((state) => ({ currentPage: page, data: { ...state.data, ...data } })),
  };
});
