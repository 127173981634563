import React from "react";
import enlace from "../../../assets/white_enlace.png";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../libs/routes";

const StaffAuthLayout = ({ title, children }) => {
  const navigate = useNavigate();

  const gotoLandingPage = () => {
    navigate(ROUTES.home);
  };
  return (
    <>
      <main className="bg-[#131842] w-full h-screen flex justify-center items-center">
        <div>
          <div className="flex flex-col gap-y-8 justify-center items-center mb-5">
            <div>
              <img
                src={enlace}
                alt="enlace logo"
                className="cursor-pointer"
                onClick={gotoLandingPage}
                width={100}
              />
            </div>
          </div>
          {children}
        </div>
      </main>
    </>
  );
};

export default StaffAuthLayout;
