import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  PostMethodWithFile,
  PostMethod,
  GetMethod,
  PatchMethod,
  UploadFile,
  PutMethod,
} from ".";
import { API_URL } from "./api_urls";

export const useSendSchedule = () => {
  const sendSchedule = useMutation({
    mutationFn: (payload) => {
      return PostMethodWithFile(API_URL.initiate_mandate, payload);
    },
  });
  return sendSchedule;
};

export const useAuthorizeMandate = (mandateID) => {
  const queryClient = useQueryClient();
  const authorizeMandate = useMutation({
    mutationFn: (payload) => {
      return PostMethod(API_URL.authorize_mandate, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("user_mandate");
    },
  });
  return authorizeMandate;
};

export const useMandateAcc = () => {
  const madnateAcc = useMutation({
    mutationFn: (accountID) => {
      return GetMethod(API_URL.get_account_mandate + accountID, "USER");
    },
  });
  return madnateAcc;
};

export const useVerifyBVN = () => {
  const verifyBvn = useMutation({
    mutationFn: (payload) => {
      return PostMethod(API_URL.verify_bvn_authorization, payload);
    },
  });
  return verifyBvn;
};
export const useAthorizeReschedule = (orderID) => {
  const authorizeReschedule = useMutation({
    mutationFn: (payload) => {
      return PatchMethod(API_URL.authorize_reschedule + orderID, payload);
    },
  });
  return authorizeReschedule;
};

//upload image for kyc
export const useUploadImage = () => {
  const uploadImage = useMutation({
    mutationFn: (payload) => {
      return UploadFile(API_URL.userImage, payload);
    },
  });
  return uploadImage;
};

export const useInitiateLoan = (paramID) => {
  const initiateLoan = useMutation({
    mutationFn: (payload) => {
      return PostMethod(API_URL.initiate_loan + paramID, payload);
    },
  });
  return initiateLoan;
};

export const useLoanKyc = (paramID) => {
  const queryClient = useQueryClient();
  const loan_kyc = useMutation({
    mutationFn: (payload) => {
      return PostMethod(API_URL.loan_kyc + paramID, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("loan_details");
    },
  });
  return loan_kyc;
};

export const useSaveAccount = (userID) => {
  const queryClient = useQueryClient();
  const save_account = useMutation({
    mutationFn: (payload) => {
      return PutMethod(API_URL.save_account + userID, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("loan_details");
    },
  });
  return save_account;
};

export const useUpdateLoan = (loanID) => {
  const queryClient = useQueryClient();
  const updateLoan = useMutation({
    mutationFn: (payload) => {
      return PutMethod(API_URL.update_loan + loanID, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("loan_details");
    },
  });
  return updateLoan;
};
