import { useEffect, useState } from "react";
import StaffAuthLayout from "../../../components/layouts/authLayout/StaffAuthLayout";
import CustomButton from "../../../components/common/CustomButton";
import { useForm } from "react-hook-form";
import {PasswordInput} from "../../../components/common/form_inputs/PasswordInput";
import { useResetPsw } from "../../../api/admin_mutation";
import { ROUTES } from "../../../libs/routes";
import { useNavigate, Link, useParams } from "react-router-dom";
import useCustomMessage from "../../../hooks/zustand_hooks/useCustomMessage";


const ResetPassword = () => {

  //external hooks
      const mutation = useResetPsw();

      const navigate = useNavigate();

      const {showMessage, contextHolder} = useCustomMessage();

      const token = useParams()?.token;


  //



  //react hooks=======================

    const [loading, setLoading] = useState(false);


  //


  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    reset,
    getValues,
    watch
  } = useForm({
    defaultValues: {
      password: "",
      confirm_password: "",
    },
  });

  const onSubmit = (data) => {
    const json = {
      password: data.password,
      confirm_password: data.confirm_password,
      token
    }

    mutation.mutate(json)

  };

  const handleChange = (e, fieldName) => {
    const value = e.target.value;

    setValue(fieldName, value);
    trigger(fieldName); // Trigger validation after setting value
  };



  //useEffect for mutation
  useEffect(()=>{
    if (mutation.isPending) {
      setLoading(true);
    } else if (mutation.isSuccess) {
      setLoading(false);
      let response = mutation?.data?.data
     
      showMessage("success", response?.data)
      navigate(ROUTES.staff_login)
      
    } else if (mutation.isError) {
      setLoading(false);
      let message = mutation?.error?.response?.data?.message
      let message2 = mutation?.error?.message
      showMessage("error", message??message2)
   
    }
  }, [mutation.status])



     // Watch the value of the "password" field
     const confirm_password = watch("password");



  return (
    <>
      <StaffAuthLayout title={"Reset Password"}>
        <main className="w-full max-w-xl mx-4">
          <form action="" onSubmit={handleSubmit(onSubmit)}>

            <div className="grid grid-cols-1 gap-x-5 md:gap-y-3">
            
              <div className="mb-3">
                <label htmlFor="" className="font-[400] text-[16px] text-white">
                  Password
                </label>
                <PasswordInput
                  name="password"
                  defaultValue={getValues("password")}
                  type="password"
                  placeholder="Enter password"
                  size={"large"}
                  className={`text-[14px] ${
                    errors.password ? "error" : ""
                  }`}
                  status={errors.password ? "error" : ""}
                  {...register("password", {
                    required: "password is required",
                  })}
                  onChange={(e) => handleChange(e, "password")}
                />
                <p>
                <small style={{ fontSize: "13px" }} className="text-red-500">
                  {errors.password && errors.password.message}
                </small>
                </p>
              </div>
              <div className="mb-3">
                <label htmlFor="" className="font-[400] text-[16px] text-white">
                  Confirm Password
                </label>
                <PasswordInput
                  name="confirm_password"
                  defaultValue={getValues("confirm_password")}
                  type="password"
                  placeholder="Enter confirm password"
                  size={"large"}
                  className={`text-[14px] ${
                    errors.confirm_password ? "error" : ""
                  }`}
                  status={errors.confirm_password ? "error" : ""}
                  {...register("confirm_password", {
                    required: "confirm_password is required",
                     validate: (value) =>
              value === confirm_password || "Passwords do not match"
                  })}
                  onChange={(e) => handleChange(e, "confirm_password")}
                />
               <p>
               <small style={{ fontSize: "13px" }} className="text-red-500">
                  {errors.confirm_password && errors.confirm_password.message}
                </small>
                </p>
              </div>
            </div>

            <div className="mt-12 mb-6 flex flex-col gap-y-2 gap-x-3 max-w-lg mx-auto">
              <CustomButton className={"w-full"} type={"submit"} loading={loading}>
                Change Password
              </CustomButton>
              </div>
          </form>
        </main>
      </StaffAuthLayout>


{contextHolder}
      
    </>
  );
};

export default ResetPassword;