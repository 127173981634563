import { Button, message } from "antd";
import { useEffect, useState } from "react";
import { useLoanDrawer } from "../../../../hooks/zustand_hooks/useLoanDrawer";
import { useCheckPayment } from "../../../../api/admin_mutation";
import { formatMoney } from "../../../../utils/formatNumber";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";

export default function SingleLoanDetail() {
  const {
    updateTitle,
    updateData,
    data: { single_schedule },
    openDrawer,
  } = useDrawer();

  const { showMessage, contextHolder } = useCustomMessage();

  const mutation = useCheckPayment(single_schedule?.id);

  const [isLoading, setIsLoading] = useState(false);

  const handleOpenDrawer = (amountDue) => {
    updateTitle("DEBIT_ACCOUNT");
    updateData({ amountDue });
  };

  const confirmPayment = () => {
    setIsLoading(true);
    mutation.mutate({});
  };

  useEffect(() => {
    if (mutation.isPending) {
      setIsLoading(true);
    } else if (mutation.isError) {
      setIsLoading(false);
      const error = mutation?.error?.response ?? mutation?.error.message;

      showMessage(
        "error",
        error?.status !== 500 ? error?.data : "Internal server error"
      );
    } else if (mutation.isSuccess) {
      setIsLoading(false);
      const res = mutation?.data?.data?.data;

      openDrawer("DEBIT_RESULT", {
        amountDue: res?.total_amount_due,
        status: "success",
        message: res?.message,
      });
    }
  }, [mutation.status]);

  return (
    <>
      <div className="w-full px-1 py-4 rounded-lg bg-white">
        <div className="flex flex-col gap-y-4">
          <div className="flex gap-5 flex-wrap">
            <p className="font-[500] text-[14px]">
              Amount Due:{" "}
              <span className="font-[300]">
                {formatMoney(
                  single_schedule?.amount - single_schedule?.amount_paid
                )}
              </span>
            </p>
            <p className="font-[500] text-[14px]">
              Due Date:{" "}
              <span className="font-[300]">{single_schedule?.start_date}</span>
            </p>
            <p className="font-[500] text-[14px]">
              Status:{" "}
              <span className="font-[300]">
                {Number(single_schedule?.amount) ===
                Number(single_schedule?.amount_paid)
                  ? "Fully Paid"
                  : Number(single_schedule?.amount) >
                      Number(single_schedule?.amount_paid) &&
                    Number(single_schedule?.amount_paid) !== 0
                  ? "Partially paid"
                  : "Not Paid"}
              </span>
            </p>
          </div>
          <div>
            <p className="font-[500] text-[14px]">
              Amount to be paid: <span className="font-[300]">5 month</span>
            </p>
            <div className="mt-4 flex flex-col gap-y-3">
              <div className="flex gap-3 items-center">
                {/* <Checkbox >Debit {Number(single_schedule?.amount) - Number(single_schedule?.amount_paid)}</Checkbox> */}
                <p>
                  Debit{" "}
                  {Number(single_schedule?.amount) -
                    Number(single_schedule?.amount_paid)}
                </p>
                <Button
                  loading={false}
                  className={`rounded-[8px] bg-[#2A2A29] text-[#FFF] hover:!bg-[#2A2A29] hover:!text-[#FFF] capitalize`}
                  onClick={() =>
                    handleOpenDrawer(
                      Number(single_schedule?.amount) -
                        Number(single_schedule?.amount_paid)
                    )
                  }
                >
                  Go to debit
                </Button>
              </div>
              <div className="flex gap-3 items-center">
                {/* <Checkbox >Customer already paid through alternative channel</Checkbox> */}
                <p>Customer already paid through alternative channel</p>
                <Button
                  loading={isLoading}
                  className={`rounded-[8px] bg-[#2A2A29] text-[#FFF] hover:!bg-[#2A2A29] hover:!text-[#FFF] capitalize`}
                  onClick={confirmPayment}
                >
                  Confirm payment
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {contextHolder}
    </>
  );
}
