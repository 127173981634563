import { GetMethod, PutMethod, PostMethodWithHeader } from ".";
import { API_URL } from "./api_urls";
import adminAuth from "../components/route_guard/adminAuth";
import axios from "axios";

const adminRole = "ADMIN";

const userRole = "USER";

//refresh token
export const refreshTokenFn = async (refreshToken, role) => {
  return await PutMethod(
    API_URL.refreshToken + refreshToken,
    role === "user" ? userRole : adminRole
  );
};

//get profile
export const getProfile = async () => {
  return await GetMethod(API_URL.get_profile, adminRole);
};
//get positions
export const getPositions = async () => {
  return await GetMethod(API_URL.get_positions, adminRole);
};
//get mandate
export const getMandates = async (payload) => {
  // const payload = queryKey[1]
  return await PostMethodWithHeader(
    API_URL.get_mandate,
    adminAuth()?.token,
    payload
  );
};
//get timeline
export const getTimelines = async ({ queryKey }) => {
  const page = queryKey[1];
  return await GetMethod(API_URL.get_timeline + page || 1, adminRole);
};
//get timeline wth ID
export const getTimelinesWithID = async ({ queryKey }) => {
  const ID = queryKey[1];
  return await GetMethod(API_URL.get_timeline_by_id + ID, adminRole);
};
//get customers
export const getCustomer = async ({ queryKey }) => {
  const page = queryKey[1];
  return await GetMethod(API_URL.get_customers + page ?? 1, adminRole);
};

export const getStaff = async () => {
  return await GetMethod(API_URL.get_staff, adminRole);
};

export const getUserMandate = async ({ queryKey }) => {
  const orderID = queryKey[1];

  return await GetMethod(API_URL.get_user_mandate + orderID, userRole);
};

//get mandate schedule
export const getMandateSchedule = async ({ queryKey }) => {
  const mandateID = queryKey[1];

  return await GetMethod(API_URL.get_schedules + mandateID, adminRole);
};

export const getUserByID = async ({ queryKey }) => {
  const userID = queryKey[1];

  return await GetMethod(API_URL.get_user_with_id + userID, adminRole);
};
export const viewSchedule = async ({ queryKey }) => {
  const scheduleID = queryKey[1];

  return await GetMethod(API_URL.view_schedule + scheduleID, adminRole);
};

export const getBankList = async () => {
  return await GetMethod(API_URL.bank_lists, adminRole);
};
export const getTransaction = async ({ queryKey }) => {
  const payload = queryKey[1];
  return await PostMethodWithHeader(
    API_URL.get_transaction,
    adminAuth()?.token,
    payload
  );
};
export const getStatatic = async ({ queryKey }) => {
  const payload = queryKey[1];
  return await PostMethodWithHeader(
    API_URL.get_statistic,
    adminAuth()?.token,
    payload
  );
};

export const getCompanyStaff = async ({ queryKey }) => {
  const companyID = queryKey[1];
  return await GetMethod(API_URL.get_company_users + companyID);
};
export const getPlans = async () => {
  return await GetMethod(API_URL.get_plans);
};
export const getLoanSettingForUser = async ({ queryKey }) => {
  const loanSettingID = queryKey[1];
  return await GetMethod(API_URL.get_loan_setting + loanSettingID);
};
export const getLoanDetails = async ({ queryKey }) => {
  const loanID = queryKey[1];
  return await GetMethod(API_URL.loan_details + loanID);
};

export const getCreditHistory = async ({ queryKey }) => {
  const bvn = queryKey[1];
  return await GetMethod(API_URL.loan_details + bvn);
};

export const getDiscos = async () => {
  return await axios.get("https://apivouch.com.ng/api/user/get_discos");
};
