import { Input, Typography, Button } from "antd";
import { useState, useEffect, useMemo } from "react";
// import OtpInput from 'react-otp-input';
import { useUserLoanApproval } from "../../../hooks/zustand_hooks/useUserLoanApproval";
import { useAuthorizeMandate, useVerifyBVN } from "../../../api/mutation";
import OtpInput from "react-otp-input";
import useCustomMessage from "../../../hooks/zustand_hooks/useCustomMessage";
import moment from "moment";

export default function BVN_OTP({ mandateID }) {
  const mutation = useVerifyBVN();

  const authorizeMandateMutation = useAuthorizeMandate(mandateID);

  const { showMessage, contextHolder } = useCustomMessage();

  const {
    updateCurrentPage,
    data: { mandate, message, company_detail },
  } = useUserLoanApproval();

  const [otp, setOtp] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChange = (text) => {
    console.log("onChange:", text);
  };

  const validateBVN = () => {
    const json = {
      otp: otp,
      mandate_id: mandate?.id,
    };

    setIsLoading(true);

    mutation.mutate(json);

    // setTimeout(()=>{
    //   updateCurrentPage("BANK_ACCOUNTS", {bank_accounts: accounts})
    //   setIsLoading(true)
    // }, 2000)
  };

  useEffect(() => {
    if (mutation.isPending) {
      setIsLoading(true);
    } else if (mutation.isError) {
      setIsLoading(false);
      const error =
        mutation?.error?.response?.data?.message ?? mutation?.error?.message;

      showMessage("error", error);
    } else if (mutation.isSuccess) {
      setIsLoading(false);
      const res = mutation?.data?.data?.data;

      console.log(mutation?.data?.data);

      const accounts = res?.accounts;

      console.log(accounts);

      updateCurrentPage("BANK_ACCOUNTS", { bank_accounts: accounts });
    }
  }, [mutation.status]);

  const resendOtp = () => {
    setLoading(true);
    const payload = {
      resend: true,
      order_id: mandateID,
    };
    authorizeMandateMutation.mutate(payload, {
      onSuccess: (data) => {
        const message = data?.data?.message;
        updateCurrentPage("BVN_OTP", { mandate: mandate, message });
      },
      onError: (err) => {
        const error = err?.response?.data?.message ?? err?.message;

        showMessage("error", error);
      },
      onSettled: () => {
        setLoading(false);
      },
    });
  };

  const resendActive = useMemo(() => {
    if (!mandate?.lastResend) return 0; // No lastResend means it's available immediately

    const lastResendTime = moment.utc(mandate.lastResend);
    const nextAllowedTime = lastResendTime.add(3, "hours");
    const now = moment.utc();

    const diffInMinutes = nextAllowedTime.diff(now, "minutes");

    return diffInMinutes > 0 ? diffInMinutes : 0; // If time has passed, return 0
  }, [mandate?.lastResend]);

  const resendMessage = useMemo(() => {
    if (resendActive === 0) return null;

    const hours = Math.floor(resendActive / 60);
    const minutes = resendActive % 60;

    return `You can Resend in next ${
      hours > 0 ? `${hours}h ` : ""
    }${minutes}m time`;
  }, [resendActive]);

  return (
    <>
      <div className="max-w-lg md:max-w-md mx-3 sm:mx-auto md:mx-auto px-1 py-4 rounded-lg bg-white shadow-sm mt-5">
        <h2 className="font-[600] text-[22px] mb-5 text-center">
          {company_detail?.name}
        </h2>
        <br />
        <h5 className="font-[400] text-[18px] text-center mb-5">
          Complete Mandate process
        </h5>

        <div className="border rounded-lg border-[#D9D9D996] px-5 py-3 mx-auto">
          {/* <Title level={4} className="tetxt-sm">{message}</Title> */}
          <p className="text-[18px] font-[500] my-3">{message}</p>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>-</span>}
            inputType="number"
            containerStyle={{
              flex: "wrap !important",
              display: "flex !important",
              textAlign: "center",
            }}
            inputStyle={{
              width: "45px",
              height: "50px",
              margin: "5px 5px",
              borderRadius: "10px",
              border: "1px solid",
            }}
            renderInput={(props) => <input {...props} />}
            onPaste={(event) => {
              const data = event.clipboardData.getData("text");
            }}
          />

          <div>
            {resendMessage ? (
              <p className="text-[#033F4B] text-[400] text-[14px] mt-2">
                If you did not receive OTP, click{" "}
                <span
                  className="text-[#8B0404] cursor-pointer relative"
                  onClick={resendOtp}
                >
                  Resend {loading && "..."}
                </span>{" "}
                to receive another one
              </p>
            ) : (
              <p className="text-[400] text-[14px] mt-2 text-center text-[#8B0404]">
                {resendMessage}
              </p>
            )}
          </div>
          <div className="mt-5 flex justify-center items-center">
            <Button
              size="large"
              shape={"round"}
              loading={isLoading}
              onClick={validateBVN}
              className="rounded-[8px] bg-[#2A2A29] text-[#FFF] text-[18px]  hover:!bg-[#2A2A29] px-4 hover:!text-[#FFF]"
            >
              Validate
            </Button>
          </div>
        </div>
      </div>

      {contextHolder}
    </>
  );
}
