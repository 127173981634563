import moment from "moment";
import { formatNumberOnly } from "../../../../../../utils/formatNumber";

const RenderTypo = ({ data }) => {
  return (
    <div>
      <h3 className="font-[500] text-[16px] text-[#4F4F4F]">{data?.title}</h3>
      {data?.detail?.map((item, index) =>
        typeof item?.value === "object" ? (
          <div className="mt-3" key={index + "____"}>
            <>
              <h3 className="font-[400] border-b text-[14px]">
                {item?.label}:
              </h3>
              <div className="mt-2">
                {item?.value.map((nextedItem, nextedIndex) => (
                  <div
                    className="flex justify-between border-b mt-2"
                    key={index + "_____" + nextedIndex}
                  >
                    <h3 className="font-[400] text-[14px]">
                      {nextedItem?.type}:
                    </h3>
                    <p className="font-[275] text-[14px]">
                      {nextedItem?.value}
                    </p>
                  </div>
                ))}
              </div>
            </>
          </div>
        ) : (
          <div className="mt-3" key={index + "____"}>
            <div className="flex justify-between border-b">
              <h3 className="font-[400] text-[14px]">{item?.label}:</h3>
              <p className="font-[275] text-[14px]">
                {item?.key_value === "dob"
                  ? item?.value?.replaceAll("-", "/")
                  : item?.value}
              </p>
            </div>
          </div>
        )
      )}
    </div>
  );
};

const CreditTypo = ({ data }) => {
  return (
    <div>
      <h3 className="font-[500] text-[16px] text-[#4F4F4F]">{data?.title}</h3>
      {data?.data?.map((item, index) => (
        <div className="mt-3" key={index + "____history"}>
          <div className="flex justify-between border-b">
            <h3 className="font-[400] text-[14px]">Financial Institution:</h3>
            <p className="font-[275] text-[14px]">{item?.institution}</p>
          </div>
          {item?.history?.map((history, historyIndex) => (
            <div className="mt-4">
              {data?.detail?.map((detail, detailIndex) => (
                <div className="border-b ps-10" key={detailIndex + "__detail"}>
                  <div className="flex justify-between mt-[0.1rem]">
                    <h3 className="font-[400] text-[14px]">{detail?.label}:</h3>
                    <p className="font-[275] text-[14px]">
                      {detail?.key_value === "date_opened"
                        ? history?.[detail?.key_value]?.replaceAll("-", "/")
                        : detail?.key_value === "tenor"
                        ? `${history?.[detail?.key_value]} ${
                            history?.repayment_frequency !== "not available"
                              ? history?.repayment_frequency
                              : ""
                          }`
                        : detail?.key_value === "opening_balance"
                        ? `${formatNumberOnly(history?.currency)} ${
                            history?.[detail?.key_value]
                          }`
                        : history?.[detail?.key_value]}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const ViewCreditHistory = ({ detailData }) => {
  const personalInfo = {
    title: "Personal Information",
    detail: [
      {
        label: "Full Name",
        key_value: "full_name",
        value: detailData?.profile?.full_name,
      },
      {
        label: "Date of Birth",
        key_value: "dob",
        value: detailData?.profile?.date_of_birth,
      },
      {
        label: "Address History",
        key_value: ["Address 1", "Address 2"],
        value: detailData?.profile?.address_history?.map((item) => {
          return { type: item?.type, value: item?.address };
        }),
      },
      {
        label: "Email",
        key_value: "email",
        value: detailData?.profile?.email_address?.join(" "),
      },
      {
        label: "Phone",
        key_value: "phone",
        value: detailData?.profile?.phone_number?.join(" "),
      },
      {
        label: "BVN",
        key_value: "bvn",
        value: detailData?.profile?.identifications?.find(
          (item) => item?.type === "BVN"
        )?.no,
      },
    ],
  };
  const creditInfo = {
    title: "Credit Information",
    data: detailData?.credit_history,
    detail: [
      { label: "Loan Date", key_value: "date_opened", value: "12/12/2020" },
      { label: "Loan Amount", key_value: "opening_balance", value: "100000" },
      {
        label: "Performance Status",
        key_value: "performance_status",
        value: "Good",
      },
      { label: "Tenor", key_value: "tenor", value: "12 months" },
      { label: "Loan Status", key_value: "loan_status", value: "Active" },
    ],
  };

  return (
    <>
      <main className="w-full max-w-lg mx-auto">
        <div className="flex flex-col gap-y-8">
          <RenderTypo
            data={personalInfo}
            title={personalInfo.title}
            detail={personalInfo.detail}
          />
          <CreditTypo data={creditInfo} />
        </div>
      </main>
    </>
  );
};

export default ViewCreditHistory;
