import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Popconfirm } from "antd";
import CustomButton from "../../../common/CustomButton";
import OutlineCustomButton from "../../../common/OutlineCustomButton";
import { Table } from "../../../common/form_inputs/Table";
import { Input } from "../../../common/form_inputs/Input";
import { useUpdateSchedule } from "../../../../api/admin_mutation";
import useCustomMessage from "../../../../hooks/zustand_hooks/useCustomMessage";
import { Button, ConfigProvider } from "antd";
import moment from "moment";
import { useDrawer } from "../../../../hooks/zustand_hooks/useDrawer";
import { formatDate } from "../../../../utils/formatDate";
import styles from "./editSchedule.module.css";
import { MdOutlineCancel } from "react-icons/md";
import { decryptData } from "../../../../utils/cryptData";

const unEditableStatus = [
  "completed",
  "cancelled",
  "part debited",
  "confirmed",
  "active",
];

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    if (!form || typeof dataIndex === "undefined" || !record) {
      console.error("Missing form, dataIndex, or record in toggleEdit");
      return;
    }

    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {}
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        // className={styles.editable_cell_value_wrap}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `This amount is required.`,
          },
        ]}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          size="small"
          className={"w-auto"}
        />
      </Form.Item>
    ) : (
      <div
        className={styles.editable_cell_value_wrap}
        // style={{
        //   paddingInlineEnd: 24,
        // }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
const EditSchedule = ({ schedules, setEditSchedule, mandateID, mandate }) => {
  //hook initialization

  const { openDrawer } = useDrawer();

  const updateSchedule = useUpdateSchedule(mandateID);

  const { showMessage, contextHolder } = useCustomMessage();

  const [dataSource, setDataSource] = useState(schedules);

  const unEditableStatus = [
    "completed",
    "cancelled",
    "part debited",
    "confirmed",
  ];

  const defaultColumns = [
    {
      title: "Due Date",
      dataIndex: "start_date",
      render: (text, record) => (
        <p className="text-nowrap">{formatDate(decryptData(text))}</p>
      ),
    },
    {
      title: <span className="text-nowra">Principal Payment</span>,
      dataIndex: "amount",
      editable: true,
      render: (text) => decryptData(text),
    },
    {
      title: <span className="text-nowra">Interest Payment</span>,
      dataIndex: "interest",
      editable: true,
      render: (text) => decryptData(text),
    },
    {
      title: <span className="text-nowrap">Total amount Due</span>,
      dataIndex: "total_amount_due",
      editable: true,
      render: (text) => decryptData(text),
    },
    {
      title: <span className="">Status</span>,
      dataIndex: "status",
      // width: "30%",
      render: (text) => (
        <div
          className={`capitalize max-w-full ${
            decryptData(text) === "not debit"
              ? "bg-red-100 text-red-900"
              : decryptData(text) === "part debited"
              ? "bg-yellow-100 text-yellow-900"
              : decryptData(text) === "fully debited"
              ? "bg-green-100 text-green-900"
              : decryptData(text) === "inactive"
              ? "bg-yellow-100 text-yellow-900"
              : decryptData(text) === "reschedule pending"
              ? "bg-purple-100 text-purple-900"
              : decryptData(text) === "reschedule declined"
              ? "bg-red-100 text-red-900"
              : decryptData(text) === "reschedule approved"
              ? "bg-green-100 text-green-900"
              : decryptData(text) === "active" && "bg-green-100 text-green-900"
          } px-2 rounded text-center`}
        >
          <i>
            {decryptData(text) === "active" ? "Not Due" : decryptData(text)}
          </i>
        </div>
      ),
    },

    {
      title: "",
      dataIndex: "action",
      render: (_, record) =>
        dataSource.length >= 1 && !record?.id ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <MdOutlineCancel
              size={"18px"}
              className="text-red-500 cursor-pointer"
            />
          </Popconfirm>
        ) : null,
    },
  ];

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const handleAddDate = () => {
    const previouseData = dataSource?.[dataSource?.length - 1];
    const newData = {
      key: dataSource?.length,
      start_date: moment(previouseData?.start_date)
        ?.add(1, "month")
        .format("YYYY-MM-DD"),
      status: "active",
      amount: 0,
      total_amount_due: 0,
      interest: 0,
    };
    setDataSource([...dataSource, newData]);
  };

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          editable: col.editable
            ? !unEditableStatus?.includes(record?.status)
            : false,
          dataIndex: col?.dataIndex,
          title: col.title,
          handleSave,
        };
      },
    };
  });

  const saveEditSchedule = () => {
    updateSchedule.mutate(dataSource, {
      onSuccess: (data) => {
        showMessage("success", data?.data?.message);
        setEditSchedule(false);

        openDrawer("DEBIT_RESULT", {
          status: "success",
          message: data?.data?.message,
        });
      },
      onError: (error) => {
        const errorMessage = error?.response?.data?.message ?? error?.message;
        showMessage("error", errorMessage);
        openDrawer("DEBIT_RESULT", {
          status: "error",
          message: errorMessage,
          redirect_name: "LOAN_DETAIL",
        });
      },
    });
  };
  return (
    <div>
      <Table
        header_bg={true}
        pagination={false}
        components={components}
        rowClassName={() => styles.editable_row}
        bordered
        dataSource={dataSource}
        columns={columns}
        size={"small"}
      />

      <div className="flex justify-start mt-5">
        <ConfigProvider
          theme={{
            components: {
              Button: {
                defaultHoverBorderColor: "#131842",
                defaultHoverBg: "#131842",
                defaultBg: "#131842",
                defaultHoverColor: "#fff",
                defaultColor: "#fff",
                defaultBorderColor: "#131842",
                defaultActiveBorderColor: "#131842",
                defaultActiveBg: "#131842",
                defaultActiveColor: "#FFF",
                defaultShadow: "0",
              },
            },
          }}
        >
          <Button
            onClick={handleAddDate}
            size={"middle"}
            disabled={moment(
              dataSource?.[dataSource?.length - 1]?.start_date
            )?.isSameOrAfter(mandate?.end_date)}
          >
            Add more repayment date
          </Button>
        </ConfigProvider>
      </div>

      <div className="w-full max-w-lg mx-auto flex justify-center gap-x-8 mt-10">
        <CustomButton
          loading={updateSchedule?.isPending}
          onClick={saveEditSchedule}
        >
          Save Schedule
        </CustomButton>
        <OutlineCustomButton
          // loading={updateSchedule?.isPending}
          onClick={() => setEditSchedule(false)}
        >
          Cancel
        </OutlineCustomButton>
      </div>

      {contextHolder}
    </div>
  );
};
export default EditSchedule;
